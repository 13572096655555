<template>
	<vueDropzone
		ref="Dropzone"
		id="dropzone"
		:options="dropzoneOptions"
		:useCustomSlot=true
		v-on:vdropzone-thumbnail="thumbnail"
		v-on:vdropzone-file-added="setPhoto"
		v-on:vdropzone-removed-file="removePhoto"
	>
		<div class="dropzone__content">
			<div class="dropzone__cloud"></div>
			<h3 class="dropzone__title">Перетащите файл с фото сюда или нажмите чтобы загрузить</h3>
			<div class="dropzone__file-size">максимальный размер файла не более 2мб</div>
		</div>
	</vueDropzone>
</template>

<script>
import('vue2-dropzone/dist/vue2Dropzone.min.css');
export default {
	components: {
		vueDropzone: () => import('vue2-dropzone')
	},
	props: ['existingPhoto'],
	data()
	{
		return {
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				maxFilesize: 10,
				maxFiles: 1,
				previewTemplate: this.template(),
				init: function ()
				{
					this.on('addedfile', function (file)
					{
						if (this.files.length > 1)
						{
							this.removeFile(this.files[1]);
						}
					});
				},
			},
		}
	},
	methods: {
		template: function ()
		{
			return `
    <div class="dz-preview dz-file-preview">
      <div class="dz-image">
        <div class="dz-photo">
          <div data-dz-thumbnail-bg class="dz-bg"></div>
        </div>
      </div>
      <div class="dz-error-mark" data-dz-remove></div>
    </div>
    `
		},

		thumbnail(file, dataUrl)
		{
			var j, len, ref, thumbnailElement;
			if (file.previewElement)
			{
				file.previewElement.classList.remove("dz-file-preview");
				ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
				for (j = 0, len = ref.length; j < len; j++)
				{
					thumbnailElement = ref[j];
					thumbnailElement.alt = file.name;
					thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
					thumbnailElement.style.backgroundRepeat = 'no-repeat';
					thumbnailElement.style.backgroundSize = '312px';
					thumbnailElement.style.backgroundPosition = 'center';
				}
				return setTimeout(((function (_this)
				{
					return function ()
					{
						return file.previewElement.classList.add("dz-image-preview");
					};
				})(this)), 1);
			}
		},
		setPhoto(file, response)
		{
			if (file.size / 1024 / 1024 < 2)
				this.$emit('setPhoto', file);
		},
		removePhoto()
		{
			this.$emit('setPhoto', false);
		}
	},
	watch:
		{
			existingPhoto()
			{
				if (this.existingPhoto)
				{
					let file = {
						size: this.existingPhoto.FILE_SIZE,
						name: this.existingPhoto.ORIGINAL_NAME,
						type: this.existingPhoto.CONTENT_TYPE
					};
					let url = this.existingPhoto.SRC;
					this.$refs.Dropzone.manuallyAddFile(file, url);
					this.$refs.Dropzone.dropzone.emit('thumbnail', file, url)
				}
			}
		},
}
</script>

<style lang="scss">
$green: #66BE6D;
.dropzone
{
	background: #F0F8F0 !important;
	height: 179px;
	margin-bottom: 22px;
	border-radius: 8px;
	border: 1px dashed $green !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding: 0 !important;

	&__content
	{
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__title
	{
		font-size: 12px;
		color: $green;
		line-height: 1.5;
		margin-bottom: 5px;
	}

	&__file-size
	{
		color: $green;
		font-size: 10px;
	}

	&__cloud
	{
		background: url("~@/assets/img/cloud.svg");
		display: flex;
		justify-content: center;
		background-size: cover;
		position: relative;
		height: 30px;
		width: 33px;
		margin-bottom: 18px;
	}
}

.dz
{
	&-success-mark, &-details, &-error-message
	{
		display: none !important;
	}

	&-preview
	{
		min-height: 0 !important;
		margin: 0 !important;
		background: transparent !important;
		width: 100%;
		height: 100%;
	}

	&-error-mark
	{
		pointer-events: auto !important;
		display: block;
		opacity: 1 !important;
		visibility: visible !important;
		position: absolute;
		top: 13px !important;
		right: 16px !important;
		cursor: pointer !important;
		height: 30px !important;
		width: 30px !important;
		text-align: end;

		&:before, &:after
		{
			content: '';
			position: absolute;
			height: 13px;
			width: 2px;
			background: #979797;
		}

		&:before
		{
			transform: rotate(-45deg);
		}

		&:after
		{
			transform: rotate(45deg);
		}
	}

	&-image
	{
		background: transparent;
		overflow: visible !important;
	}

	&-bg
	{
		height: 178px;
		width: 178px;
		border-radius: 50%;
		border: 1px dashed $green;
	}

	&-photo
	{
		display: flex;
		justify-content: center;
	}
}
</style>